import * as types from '../../constants/action-types';
import { CARRIER_TOOLTIP, CLOUD_FRONT_URL } from 'constants/constant';

const initState = () => ({
  loader: false,
  dashboard: JSON.parse(localStorage.getItem('dashboard')) || false, // true : dashboard  false: post signup steps
  initURL: '',
  alertMessage: '',
  showMessage: false,
  authUser: localStorage.getItem('user_id'),
  loginName: localStorage.getItem('user_id'),
  producer_id: localStorage.getItem('producer_id'), // producer_id is required for prefrech data for all steps
  userRole: localStorage.getItem('role_category'), // admin / producer
  role: localStorage.getItem('role'),
  userLastName: localStorage.getItem('user_last_name'),
  complianceInfo: {},
  // localStorage / redux auth token is unique to legacy auth implementation and should be removed as soon as possible
  authToken: localStorage.getItem('Authorization'),
  page: '',
  updateDefaultPassword:
    JSON.parse(localStorage.getItem('updateDefaultPassword')) || false,
  signupFlow: localStorage.getItem('signupFlow'),
  taxStatus: null,
  agencyName: null,
  producer_code: localStorage.getItem('producer_code'),
  fkParentProducerId: localStorage.getItem('fkParentProducerId'), // fkParentProducerId is required for agency profile
  isWholesaler: JSON.parse(localStorage.getItem('isWholesaler')) || false,
  appointments: JSON.parse(localStorage.getItem('appointments')),
  showSSNEditPrompt: false,
  userStatus: localStorage.getItem('userStatus'),
  hasQuotingEngineAccess: JSON.parse(
    localStorage.getItem('hasQuotingEngineAccess')
  ),
  has_accepted_carriers_appointment_prompt:
    JSON.parse(
      localStorage.getItem('has_accepted_carriers_appointment_prompt')
    ) || false,
  producerEmail: localStorage.getItem('email'),
  crmContactId: JSON.parse(localStorage.getItem('crmContactId')),
  floodRaterCarrier: JSON.parse(localStorage.getItem('floodRaterCarrier'))
    ?.floodRaterCarrier,
  tendCarrier: JSON.parse(localStorage.getItem('tendCarrier'))?.tendCarrier,
  calloutIncentives: [],
  phoneNumber: '',
  principleFirstName: localStorage.getItem('principleFirstName') || '',
  principleLastName: localStorage.getItem('principleLastName') || '',
  isCrossSellExperience: false,
  shouldShowProgressBar: false,
  progressBarLoader: false,
  carrierAppointmentRequestCount:
    JSON.parse(localStorage.getItem('carrierAppointmentRequestCount')) || 0,
  carrierStoreAgentApplicationRestrictionEnabled:
    JSON.parse(
      localStorage.getItem('carrierStoreAgentApplicationRestrictionEnabled')
    ) || false,
  carrierStoreThankYouModal: false,
  enableMenuItem: JSON.parse(localStorage.getItem('enableMenuItem')) || false,
  isDontUpdateMe: false,
  daysRemaining: localStorage.getItem('daysRemaining'),
  earningSettings: localStorage.getItem('earningSettings'),
  isInitialModalViewed: JSON.parse(
    localStorage.getItem('isInitialModalViewed')
  ),
  isSecondaryModalViewed: JSON.parse(
    localStorage.getItem('isSecondaryModalViewed')
  ),
  isTertiaryModalViewed: JSON.parse(
    localStorage.getItem('isTertiaryModalViewed')
  ),
  shouldShowFastStart:
    JSON.parse(localStorage.getItem('shouldShowFastStart')) || false,
  cloudFrontUrl: localStorage.getItem(CLOUD_FRONT_URL) || '',
  carrierTooltip: localStorage.getItem(CARRIER_TOOLTIP) || '',
  producerLoggedIn: false,
  /** @type {string | null} */
  activatedAt: localStorage.getItem('activatedAt') || null,
  /** @type {boolean} */
  isStateInitialized:
    JSON.parse(localStorage.getItem('isStateInitialized')) || false,
  availableCarrierCount:
    JSON.parse(localStorage.getItem('availableCarrierCount')) || null,
  /** @type {'emailStandalone' | 'loading' | 'passwordless' | null} */
  signInFormCustomStep: 'emailStandalone',
  /** @type {boolean} Global loader for handling application loading state after successful sign in. */
  isGlobalAuthLoaderShown: false,
  isUserEmailVerified: false,
  /** @type {number} Global unseen notifications count */
  unseenNotificationsCount: 0
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.UPDATE_SIGN_IN_FORM_CUSTOM_STEP: {
      return { ...state, signInFormCustomStep: action.payload };
    }

    case types.SET_IS_GLOBAL_AUTH_LOADER_SHOWN: {
      return { ...state, isGlobalAuthLoaderShown: action.payload };
    }

    case types.INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case types.SET_DASHBOARD_VIEW: {
      return {
        ...state,
        dashboard: action.payload,
      };
    }

    case types.SET_DONT_UPDATE_ME: {
      return {
        ...state,
        isDontUpdateMe: action.payload,
      };
    }

    case types.SET_PRODUCER_LOGGEDIN: {
      return {
        ...state,
        producerLoggedIn: action.payload,
      };
    }

    case types.SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authToken: null,
        authUser: null,
        initURL: '',
        loader: false,
        userRole: null,
        loginName: null,
        signupFlow: null,
        floodRaterCarrier: null,
        tendCarrier: null,
      };
    }

    case types.SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
        page: action.payload.page,
        // localStorage / redux auth token is unique to legacy auth implementation and should be removed as soon as possible
        authToken: action.payload.authToken,
        updateDefaultPassword: action.payload.updateDefaultPassword,
        hasAcceptedAgreement: action.payload.hasAcceptedAgreement,
        appointments: action.payload.appointments,
        hasQuotingEngineAccess: action.payload.hasQuotingEngineAccess,
        shouldShowProgressBar: action.payload.shouldShowProgressBar,
      };
    }
    case types.SET_CARRIER_STORE_DETAILS: {
      return {
        ...state,
        ...action.payload,
        carrierAppointmentRequestCount:
          action.payload.carrierAppointmentRequestCount,
        carrierStoreAgentApplicationRestrictionEnabled:
          action.payload.carrierStoreAgentApplicationRestrictionEnabled,
      };
    }
    case types.UPDATE_CARRIER_APPOINTMENT_REQUEST_COUNT: {
      return {
        ...state,
        carrierAppointmentRequestCount: action.payload,
      };
    }
    case types.CARRIER_STORE_THANK_YOU_MODAL_CLOSE: {
      return {
        ...state,
        carrierStoreThankYouModal: action.payload,
      };
    }
    case types.USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case types.SET_PROGRESS_BAR_FLAG: {
      return {
        ...state,
        shouldShowProgressBar: action.payload,
      };
    }
    case types.SET_PROGRESS_BAR_Loader: {
      return {
        ...state,
        progressBarLoader: action.payload,
      };
    }
    case types.ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case types.SET_CROSS_SELL_DETAILS: {
      return {
        ...state,
        isCrossSellExperience: action.payload,
      };
    }
    case types.ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    case types.SET_CROSS_SELL_VIEWED: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.GET_PROGRESS_BAR_FLAG: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }

    case types.HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false,
      };
    }

    case types.UPDATE_AUTH_NAME: {
      return {
        ...state,
        loginName: action.payload,
      };
    }

    case types.UPDATE_AUTH_LAST_NAME: {
      return {
        ...state,
        userLastName: action.payload,
      };
    }

    case types.UPDATE_COMPLIANCE_STATUS: {
      const { eno, license, w9 } = action.payload;
      return {
        ...state,
        complianceInfo: {
          eno_status: eno,
          license_status: license,
          w9_status: w9,
        },
      };
    }

    case types.UPDATE_DEFAULT_PASSWORD: {
      return {
        ...state,
        updateDefaultPassword: action.payload,
      };
    }

    case types.SET_WHOLESALER: {
      return {
        ...state,
        isWholesaler: action.payload,
      };
    }

    case types.SET_CARRIER_FLOOD_RATER: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.SET_CARRIER_TEND: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.SET_CLOUD_FRONT_URL: {
      return {
        ...state,
        cloudFrontUrl: action.payload,
      };
    }

    case types.SET_CARRIER_TOOLTIP: {
      return {
        ...state,
        carrierTooltip: action.payload,
      };
    }

    case types.UPDATE_SSN_EDIT_PROMPT_VALUE: {
      return {
        ...state,
        showSSNEditPrompt: action.payload,
      };
    }

    case types.SET_AUTH_INFO: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.SET_STATE_INITIALIZED: {
      return {
        ...state,
        isStateInitialized: action.payload,
      };
    }

    case types.SET_PRODUCER: {
      const { payload } = action;
      return {
        ...state,
        phoneNumber: payload.phone_number,
        availableCarrierCount: payload.availableCarrierCount,
        agencyProfile: payload.agencyProfile
          ? {
              ...state.agencyProfile,
              state: payload.agencyProfile.state || state.agencyProfile?.state,
              address:
                payload.agencyProfile.address || state.agencyProfile?.address,
              city: payload.agencyProfile.city || state.agencyProfile?.city,
              zipCode:
                payload.agencyProfile.zipCode || state.agencyProfile?.zipCode,
            }
          : state.agencyProfile,
      };
    }

    case types.SET_IS_USER_EMAIL_VERIFIED: {
      return {
        ...state,
        isUserEmailVerified: action.payload,
      };
    }

    case types.SET_UNSEEN_NOTIFICATIONS_COUNT: {
      return {
        ...state,
        unseenNotificationsCount: action.payload,
      };
    }

    default:
      return state;
  }
};
