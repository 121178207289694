import React, { Component } from 'react';
import MomentUtils from '@date-io/moment';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MainApp from 'app/index';
import 'assets/vendors/style';
import {
  CARRIER_STORE_AGENT_APPLICATION_RESTRICTION_ENABLED,
  DEFAULT_THEME,
  IMPERSONATE_DATA,
  PRODUCER_DASHBOARD,
  TRAILING_SLASH_URL,
} from 'constants/constant';
import { isNull, isUndefined } from 'lodash';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { IntlProvider } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import {
  fetchPermissions,
  setInitUrl,
  userAutoSignIn,
  userSignOut,
} from 'redux/actions/auth';
import {
  clearImpersonateMode,
  setImpersonateData,
} from 'redux/actions/impersonate';
import { setModulePermissions } from 'redux/actions/module-permissions';
import { stepDataFetch } from 'redux/actions/post-sign-up';
import asyncComponent from 'util/async-component';
import {
  getAuthDetails,
  getDefaultPath,
  getModulePermission,
} from 'util/extra';
import SignupProcess from './sign-up-process';
import RTL from 'util/rtl';
import AppLocale from '../lang-provider';
import ChangePassword from './change-password';
import EmailVerification from './email-verification';
import ForgotPassword from './forgot-password';
import PostSignup from './post-signup';
import SignIn from './sign-in';
import ReactGA from 'react-ga';
import axios from 'util/api';
import { userSignIn } from '../redux/actions/auth';
import SignupTransition from './signup-transition';
import RefreshState from 'app/routes/producer/routes/refresh-state';
import ClearImpersonate from 'app/routes/producer/routes/clear-impersonate';
import config, { env } from 'config';
import { getUserRoleFromUrl, setRedirectUrlCookie } from 'util/redirect-url';
import {
  CLEAR_IMPERSONATE,
  POST_SIGNOUT_ROUTE,
  REAUTHENTICATE_ROUTE,
  REFRESH_STATE_ROUTE,
  SIGNIN_ROUTE,
  SIGNOUT_ROUTE,
  START_ROUTE,
} from 'constants/routes';
import { CoralogixRum } from '@coralogix/browser';
import SignOutPage from './signout/signout';
import ReauthenticatePage from './reauthenticate/reauthenticate';
import InactivitySignoutWatcher from './inactivity-signout-watcher/inactivity-signout-watcher';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { FlagshipProvider } from './flagship';
import { getToken } from 'util/auth/get-token';

ReactGA.initialize(config.GA_TRACKING_ID);

if (config.CORALOGIX_PUBLIC_KEY_LEGACY && config.CORALOGIX_APP_NAME_LEGACY) {
  const escapeRegExp = (str) => str.replaceAll(/[$()*+.?[\\\]^{|}]/g, '\\$&'); // $& means the whole matched string
  const createBlacklistedUrlsRegex = (urls) => {
    return new RegExp(`^((?!${urls.join('|')}).)*$`, 'i');
  };

  // Regex matching all URLs which are _not_ API/BFF.
  const blacklistedUrlRegex = createBlacklistedUrlsRegex([
    config.API_URL,
    config.BFF_URL,
  ]);

  CoralogixRum.init({
    public_key: config.CORALOGIX_PUBLIC_KEY_LEGACY,
    application: config.CORALOGIX_APP_NAME_LEGACY,
    version: '1.0',
    coralogixDomain: 'US1',
    ignoreUrls: [blacklistedUrlRegex],
    traceParentInHeader: {
      enabled: true,
      options: {
        // Propagate trace header to API and BFF
        propagateTraceHeaderCorsUrls: [
          new RegExp(escapeRegExp(config.API_URL) + '.*'),
          new RegExp(escapeRegExp(config.BFF_URL) + '.*'),
        ],
      },
    },
    instrumentations: {
      xhr: true,
      fetch: true,
      web_vitals: true,
      interactions: true,
      custom: true,
      errors: true,
      long_tasks: true,
      resources: true,
    },
    debug: env !== 'production',
  });
} else {
  // eslint-disable-next-line no-console
  console.warn('Coralogix config not found - skipping initialization');
}

// eslint-disable-next-line
const RestrictedRoute = ({
  component: RouteComponent,
  authUser,
  isSignedUpByReferral,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authUser || isSignedUpByReferral ? (
        <RouteComponent {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openChangePasswordModal: false,
      updateImpersonateReducer: false,
      openConfirmationDialog: false,
      redirectToSignIn: true,
    };
  }

  UNSAFE_componentWillMount() {
    const {
      authUser,
      dashboard,
      history,
      initURL,
      producer_id,
      stepDataFetchRequired,
      updateDefaultPassword,
    } = this.props;
    // eslint-disable-next-line
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initURL === '') {
      this.props.setInitUrl(history.location.pathname);
    }
    if (
      !dashboard &&
      authUser !== null &&
      stepDataFetchRequired &&
      !updateDefaultPassword
    ) {
      this.props.stepDataFetch({
        producer_id: producer_id || localStorage.getItem('producer_id'),
      });
    }
    if (!localStorage.getItem('role_category') && !localStorage.email) {
      if (!!this.props.preSignup.email) {
        this.props.userSignOut();
      }
    }
  }

  componentDidMount() {
    this.redirectToImpersonateDashboard();
    let token = localStorage.getItem('Authorization');
    let impersonateData =
      !!localStorage.getItem('impersonateData') &&
      JSON.parse(localStorage.getItem('impersonateData'));

    if (token) {
      this.props.fetchPermissions({ token });
    }
    if (impersonateData?.isImpersonateMode) {
      const { permissions, producer } = impersonateData;
      this.props.setImpersonateData({
        permissions: permissions,
        producer: { ...producer, ...producer.fastStart },
        isImpersonate: true,
      });
    }
    const {
      router: {
        location: { pathname = '', search },
      },
    } = this.props;

    const PATHS_EXCLUDED_FROM_LOGIN_REDIRECT = [
      // Initial page
      START_ROUTE,
      // Refresh State page is handled separately in RefreshState component
      REFRESH_STATE_ROUTE,
      // Auth0 callback redirects back to /signin which would overwrite the original redirectUrl
      SIGNIN_ROUTE,
      CLEAR_IMPERSONATE,
      POST_SIGNOUT_ROUTE,
      SIGNOUT_ROUTE,
      REAUTHENTICATE_ROUTE,
    ];
    if (!PATHS_EXCLUDED_FROM_LOGIN_REDIRECT.includes(pathname)) {
      const userRole = getUserRoleFromUrl(pathname);
      setRedirectUrlCookie(`${pathname}${search}`, userRole);
    }

    const getHubspotToken = () => {
      getToken().then((authToken) => {
        // If not authenticated, avoid call resulting in 401. Most probably whole approach can be refactored.
        if (!authToken || !this.props.producerEmail) {
          return;
        }
        axios
          .get('/api/v2/hubspot/getVisitorIdentificationToken')
          .then((data) => {
            window.hsConversationsSettings = {
              identificationEmail: this.props.producerEmail,
              identificationToken: data.data.hubspotToken,
            };
            window.HubSpotConversations.widget.load();
          })
          .catch((err) => {
            // P0 - FCACT-1115 - Need to handle error
            console.error(err);
          });
      });
    };

    if (window.HubSpotConversations) {
      getHubspotToken();
    } else {
      window.hsConversationsOnReady = [getHubspotToken];
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.redirectToImpersonateDashboard();
    const { loader, signupStatus, isAgreementAccepted } = this.props;
    const { isAgreementAccepted: isCompletedSignupSteps } = newProps;
    const token = localStorage.getItem('Authorization');
    if (
      token &&
      isCompletedSignupSteps &&
      isCompletedSignupSteps !== isAgreementAccepted
    ) {
      this.props.fetchPermissions({ token });
    }

    if (!this.state.redirectToSignIn && (signupStatus || loader)) {
      this.setState({ redirectToSignIn: false });
    }
    window.addEventListener('storage', this.localStorageChange);
    const { updateDefaultPassword } = this.props;
    updateDefaultPassword && this.setState({ openChangePasswordModal: true });
    return () => {
      window.removeEventListener('storage', this.localStorageChange);
    };
  }

  // TODO: Need to remove function once url is updated in backend .
  redirectToImpersonateDashboard = () => {
    const { impersonate, history } = this.props;
    if (!!impersonate?.isImpersonate) {
      const { pathname } = !!history?.location && history.location;
      if (!!pathname && pathname === PRODUCER_DASHBOARD) {
        history.replace('/app/admin/dashboard');
      }
    }
  };

  localStorageChange = (e) => {
    const {
      history,
      // eslint-disable-next-line no-shadow
      setImpersonateData,
      authToken,
      enableMenuItem,
      carrierAppointmentRequestCount,
      carrierStoreAgentApplicationRestrictionEnabled,
      isNewCarrierStoreEnabled,
    } = this.props;
    let impersonateData = !!localStorage.getItem('impersonateData')
      ? JSON.parse(localStorage.getItem('impersonateData'))
      : null;

    const { key } = e;
    if (key === IMPERSONATE_DATA) {
      const { isImpersonateMode, producer, permissions } =
        !!impersonateData && impersonateData;
      if (!isImpersonateMode) {
        history.push(localStorage.getItem('activeUrl'));
      } else {
        const match = {
          url: window.location.pathname
            .split('/')
            .splice(0, 3)
            .toString()
            .replace(/,/g, '/'),
        };
        const { isWholesaler, producer_id, producer_code } = producer;
        let getPath =
          !!permissions &&
          getDefaultPath({
            module: permissions,
            match,
            isWholesaler,
            producerDetails: { producer_id, producer_code },
            carrierStoreAgentApplicationRestrictionEnabled:
              carrierStoreAgentApplicationRestrictionEnabled ||
              JSON.parse(
                localStorage.getItem(
                  CARRIER_STORE_AGENT_APPLICATION_RESTRICTION_ENABLED
                )
              ),
            carrierAppointmentRequestCount,
            enableMenuItem,
            availableCarrierCount: JSON.parse(
              localStorage.getItem('availableCarrierCount') || null
            ),
            isNewCarrierStoreEnabled,
          });
        !!getPath && history.replace(getPath);
      }
      setImpersonateData({
        producer: { ...producer, ...producer.fastStart },
        permissions: permissions,
        isImpersonate:
          !isUndefined(isImpersonateMode) && !isNull(isImpersonateMode)
            ? isImpersonateMode
            : false,
      });
    } else if (!key && authToken) {
      this.props.userSignOut();
    }
  };

  render() {
    const {
      userRole,
      match,
      location: { pathname },
      locale,
      authUser,
      initURL,
      isDirectionRTL,
      signupStatus,
      dashboard,
      isSignedUpByReferral,
      producerCode,
      impersonate,
    } = this.props;
    const { redirectToSignIn } = this.state;

    let themeColor = DEFAULT_THEME;

    document.body.classList.add(themeColor);
    document.getElementsByTagName('body')[0].className.match(DEFAULT_THEME);
    document.body.classList.replace(DEFAULT_THEME, themeColor);
    if (['/', '/app', '/app/'].includes(pathname)) {
      if (authUser === null || authUser) {
        return redirectToSignIn ? (
          <Redirect to={'/signin'} />
        ) : (
          <Redirect to={'/pre-signup'} />
        );
      } else if (
        initURL === '' ||
        initURL === '/' ||
        initURL === '/signin' ||
        signupStatus
      ) {
        if (!dashboard) {
          return <Redirect to={'/post-signup'} />;
        } else if (!!userRole) {
          return <Redirect to={`/app/${userRole}`} />;
        }
      }
    }

    const applyTheme = createMuiTheme('');
    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }
    const currentAppLocale = AppLocale[locale.locale];
    const { producer: impersonateProducer, isImpersonate } = impersonate;
    const flagshipProducerCode = isImpersonate
      ? impersonateProducer.producer_code
      : producerCode;

    return (
      <InactivitySignoutWatcher>
        <GoogleReCaptchaProvider
          reCaptchaKey={config.GOOGLE_RECAPTCHA_SITE_KEY}
          useEnterprise={true}
          scriptProps={{
            async: true,
            defer: true,
          }}
        >
          <FlagshipProvider producerCode={flagshipProducerCode}>
            <MuiThemeProvider theme={applyTheme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <IntlProvider
                  locale={currentAppLocale.locale}
                  messages={currentAppLocale.messages}
                >
                  <RTL>
                    <div className={`app-main`}>
                      <Switch>
                        <Redirect
                          from={TRAILING_SLASH_URL}
                          to={pathname.slice(0, -1)}
                        />
                        <RestrictedRoute
                          path={`${match.url}app`}
                          authUser={authUser}
                          component={MainApp}
                        />
                        {/* TO be removed after SIGNOUT route is deployed; kept to avoid breaking change */}
                        <Route
                          path={POST_SIGNOUT_ROUTE}
                          component={SignOutPage}
                        />
                        <Route path={SIGNOUT_ROUTE} component={SignOutPage} />
                        <Route
                          path={REAUTHENTICATE_ROUTE}
                          component={ReauthenticatePage}
                        />
                        <Route
                          path={`${match.url}post-signup`}
                          component={PostSignup}
                        />
                        <RestrictedRoute
                          path={`${match.url}post-signup`}
                          authUser={authUser}
                          isSignedUpByReferral={isSignedUpByReferral}
                          component={PostSignup}
                        />
                        <Redirect
                          exact
                          from={`${match.url}pre-signup`}
                          to={`${match.url}pre-signup/signupprocess`}
                        />
                        <Route
                          path={`${match.url}pre-signup/signupprocess`}
                          component={SignupProcess}
                        />
                        <Route
                          exact
                          path={`${match.url}emailVerification`}
                          component={EmailVerification}
                        />
                        <Route
                          exact
                          path={`${match.url}signin`}
                          component={SignIn}
                        />
                        <Route
                          exact
                          path={`${match.url}forgotpassword`}
                          component={ForgotPassword}
                        />
                        <Route
                          exact
                          path={`${match.url}changepassword`}
                          component={() => {
                            const { search } = useLocation();
                            window.location.href = `/changepassword.html${search}`;
                          }}
                        />
                        <Route
                          exact
                          path={`${match.url}signup-transition`}
                          component={SignupTransition}
                        />
                        <Route
                          path={'/changepassword.html'}
                          component={ChangePassword}
                        />
                        <Route
                          exact
                          path={REFRESH_STATE_ROUTE}
                          component={RefreshState}
                        />
                        <Route
                          exact
                          path={'/clear-impersonate'}
                          component={ClearImpersonate}
                        />
                        <Route
                          component={asyncComponent(() =>
                            import('components/error-404')
                          )}
                        />
                      </Switch>
                    </div>
                    <NotificationContainer />
                  </RTL>
                </IntlProvider>
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
          </FlagshipProvider>
        </GoogleReCaptchaProvider>
      </InactivitySignoutWatcher>
    );
  }
}

const mapStateToProps = ({
  settings,
  auth,
  preSignup,
  modulePermissions,
  postSignup,
  impersonate,
  router,
  producerSettings,
}) => {
  const { sideNavColor, locale, isDirectionRTL } = settings;
  const { isNewCarrierStoreEnabled } = producerSettings;
  const { loader, signupStatus } = preSignup;
  const { permissions, producer, isImpersonate } = impersonate;
  const { userRole, authUser, initURL, dashboard, updateDefaultPassword } =
    getAuthDetails({
      auth,
      producer,
      isImpersonate,
    });
  let modulePermissionsData = getModulePermission(
    modulePermissions.modulePermissionsData,
    permissions,
    isImpersonate
  );
  const { stepDataFetchRequired, isSignedUpByReferral, isAgreementAccepted } =
    postSignup;
  const {
    authToken,
    carrierStoreAgentApplicationRestrictionEnabled,
    carrierAppointmentRequestCount,
    enableMenuItem,
    producerEmail,
  } = auth;
  return {
    userRole,
    sideNavColor,
    locale,
    isDirectionRTL,
    authUser,
    initURL,
    signupStatus,
    dashboard,
    modulePermissionsData,
    updateDefaultPassword,
    impersonate,
    router,
    preSignup,
    loader,
    stepDataFetchRequired,
    isSignedUpByReferral,
    authToken,
    enableMenuItem,
    carrierAppointmentRequestCount,
    carrierStoreAgentApplicationRestrictionEnabled,
    producerEmail,
    isAgreementAccepted,
    isNewCarrierStoreEnabled,
  };
};

export default connect(mapStateToProps, {
  userAutoSignIn,
  setInitUrl,
  stepDataFetch,
  setModulePermissions,
  clearImpersonateMode,
  setImpersonateData,
  userSignOut,
  userSignIn,
  fetchPermissions,
})(App);
