// @ts-check

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './settings';
import Auth from './auth';
import Common from './common';
import PostSignup from './post-sign-up';
import PreSignup from './pre-sign-up';
import SignIn from './sign-in';
// --------------Producer/Agent----------------
import PasswordSettings from './update-password';
import AgentDashboard from './dashboard';
import MyCommission from './my-commission';
import MyAccount from './my-account';
// ----------------Admin-----------------------
// Agent Directory
import AgentDetails from './agent-details';
import Commissions from './commissions';
import AdhocPayments from './adhoc-payments';
import CarrierList from './carrier-list';
import CarrierAppointmentList from './carrier-appointment-list';
import CarrierAppointmentSettings from './carrier-appointment-settings';
import CarrierAppointmentRequest from './carrier-appointment-request';
// Carrier Onboard
import CarrierOnboardList from './carrier-on-board-list';
import CarrierDetails from './carrier-details';
// Carrier Appointment Settings Admin
import CarrierAppointmentAdmin from './carrier-appointment-settings-admin';
// Common
import DocumentDownload from './document-download';
// Report
import Reports from './reports';
// Module Permission
import ModulePermissions from './module-permissions';
// ----------------Permission-----------------------
import RolePermission from './role-permission';
import UserPermission from './user-permission';
// ----------------Advance Search-----------------------
import AdvanceSearch from './advance-search';
// ----------------Quote Connector-----------------------
import QuoteConnector from './quote-connector';
import Impersonate from './impersonate';
// ----------------Product-----------------------
import Product from './product';
// ---------------Admin User----------------
import AdminUsers from './admin-users';
// --------------- LOA Filters ------------------
import LOAFilters from './loa-filters';
// --------------- Organization details ------------------
import OrganizationDetails from './organization-details';
// --------------- LOA Filters ------------------
import CarrierCredentials from './carrier-credentials';
// --------------- Incentive Banner ------------------
import incentiveBanner from './incentive-banner';

import CarrierCategories from './carrier-categories';

import classCodes from './class-codes';
// ---------------Bulk Appointments----------------
import bulkAppointments from './bulk-appointments';

// ---------------Agent Messaging----------------
import agentMessaging from './agent-messaging';

// ---------------Fast Start----------------
import fastStart from './fast-start';

// ----------------Q2 Incentive--------------
import q2Incentive from './q2-incentive';

// ----------------Leaderboard--------------
import leaderboard from './leaderboard';

// ----------------Q3/Q4 Incentive--------------
import incentive from './incentives';

// ----------------Producer Settings--------------
import producerSettings from './producer-settings';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    passwordSettings: PasswordSettings,
    auth: Auth,
    adhocPayments: AdhocPayments,
    commonData: Common,
    postSignup: PostSignup,
    preSignup: PreSignup,
    signIn: SignIn,
    agentDetails: AgentDetails,
    commissions: Commissions,
    carriers: CarrierList,
    dashboard: AgentDashboard,
    myAccount: MyAccount,
    myCommission: MyCommission,
    documentDownload: DocumentDownload,
    carrierOnboard: CarrierOnboardList,
    carrierDetails: CarrierDetails,
    reports: Reports,
    modulePermissions: ModulePermissions,
    rolePermission: RolePermission,
    userPermission: UserPermission,
    advanceSearch: AdvanceSearch,
    quoteConnector: QuoteConnector,
    impersonate: Impersonate,
    product: Product,
    adminUsers: AdminUsers,
    incentiveBanner: incentiveBanner,
    loaFilters: LOAFilters,
    organizationDetails: OrganizationDetails,
    carrierCredentials: CarrierCredentials,
    carriersAppointmentList: CarrierAppointmentList,
    carrierAppointmentSettings: CarrierAppointmentSettings,
    carrierAppointmentRequest: CarrierAppointmentRequest,
    carrierAppointment: CarrierAppointmentAdmin,
    carrierCategories: CarrierCategories,
    classCodes: classCodes,
    bulkAppointments: bulkAppointments,
    agentMessaging: agentMessaging,
    fastStart: fastStart,
    q2Incentive: q2Incentive,
    leaderboard: leaderboard,
    incentive: incentive,
    producerSettings: producerSettings,
  });
